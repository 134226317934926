@import url("react-responsive-carousel/lib/styles/carousel.min.css");
@import "react-responsive-carousel/lib/styles/carousel.min.css";
@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@400&display=swap");

$alegreya-sans: "Alegreya Sans", serif;
$prompt: "Prompt", serif;

$myColor: #398378;
$myBackgroundColor: #ffff;
$fontSecondaryColor: black;
$fontThirdColor: #31c48d;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  color: $myColor;
  background-color: $myBackgroundColor;
}

img {
  width: 100%;
}

// Navbar
.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  nav {
    background-color: $myBackgroundColor;
    width: 90%;
  }

  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
    margin: 20px auto;
    :hover {
      color: #31c48d;
    }
  }

  li {
    display: flex;
    font-family: $alegreya-sans;
    font-size: 20px;
  }
}

// Burger Menu
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
  background: #078080;
  z-index: 100;
}

.burger_btn {
  outline: none;
  border: none;
  cursor: pointer;
  position: fixed;
  top: 18px;
  left: 15px;
  width: 50px;
  height: 50px;
  background: transparent;
  z-index: 100;
}

.burger_ul,
.burger_li {
  margin: 0;
  padding: 0;
}

.burger_ul {
  padding: 25px;
  position: fixed;
  top: 100px;
  width: 230px;
  z-index: 100;
}

.burger_li {
  list-style: none;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.text {
  color: #fef6e4;
}

@media (min-width: 800px) {
  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-right: 30px;
  }
  .burger_btn {
    display: none;
    visibility: hidden;
  }
}
@media (max-width: 800px) {
  .navbar_container {
    visibility: hidden;
  }
  .header-container {
    margin-bottom: -45px;
  }
}

.nav-link {
  color: $myColor;
  text-decoration: none;
}

.active-nav {
  color: #31c48d;
  font-size: 50px;
}

.logo {
  width: 14rem;
  height: 13rem;
}
.logo-2 {
  width: 5rem;
  height: 5rem;
}

.carousel {
  image-rendering: auto;
}

@media (min-width: 100px) {
  .carousel {
    height: 250px;
  }
}
@media (min-width: 500px) {
  .carousel {
    height: 400px;
  }
}
@media (min-width: 800px) {
  .carousel {
    height: 500px;
  }
}

.about {
  display: flex;
  align-self: flex-start;
  padding: 20px 20px;
  font-family: $alegreya-sans;
  color: $fontSecondaryColor;
  background-color: #31c48d;

  h2 {
    max-width: 100px;
  }
  p {
    font-size: 20px;
    padding: 20px 10px;
    margin-left: 10px;

    color: #ffff;
  }
}

.line-1 {
  border-left: 6px solid white;
  height: 8rem;
  margin-left: 30px;
}

@media (max-width: 500px) {
  .about {
    h2 {
      padding: 20px 2px;
    }
    .line-1 {
      margin-top: 15px;
      height: 12rem;
    }
  }
}

#header_1 {
  display: flex;
  color: black;
  font-family: $alegreya-sans;
  margin-top: 5rem;
  margin-left: 3rem;
}
.line-3 {
  background-color: $fontThirdColor;
  height: 35px;
  width: 2px;
  margin-right: 8px;
}

.services-container {
  margin-top: 10rem;
  color: $myColor;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.grid-container {
  font-family: $alegreya-sans;
  color: $fontSecondaryColor;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 10px;
  gap: 20px;
  margin-bottom: -50px;
  align-items: center;

  div {
    width: 200px;
    height: 200px;
    box-shadow: rgb(99 99 99 / 20%) 2px 0px 6px 0px;
    border-radius: 1px;
  }

  @media screen and (min-width: "900px") {
    div {
      width: 300px;
      height: 300px;
      p {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-family: $alegreya-sans;
        color: black;
        margin-top: 0.5rem;
        font-size: 25px;
        margin-left: 3px;
      }
    }
  }

  img {
    height: 80%;
  }
  p {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: $alegreya-sans;
    color: black;
    margin-top: 0.5rem;
    font-size: 18px;

    .line-2 {
      border-left: 2px solid #7dcea0;
      height: 1.2rem;
      margin-right: 4px;
      margin-left: 3px;
    }
  }
}

@media screen and (max-width: 680px) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr;
    padding: 10px;
    gap: 20px;
  }
  #header_1 {
    display: flex;
    justify-content: center;
    align-self: center;
    margin-left: 0;
  }
}

@media (min-width: 900px) {
  #header_1 {
    font-size: 3rem;
  }
  .line-3 {
    height: 50px;
  }
}

.reviews-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5rem;
  height: 40rem;
  background-color: $myColor;
  h1 {
    margin-top: 1rem;
    color: white;
    font-family: $alegreya-sans;
  }
  h3 {
    margin-top: 2rem;
    color: $fontThirdColor;
    font-family: $alegreya-sans;
    font-size: 22px;
  }
}

@media (max-width: 600px) {
  .reviews-container {
    height: 35rem;
    h1 {
      margin-top: 5px;
    }
    h3 {
      margin: 30px 30px;
      text-align: center;
    }
  }
}

.carousel_reviews {
  width: 70%;
  div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: #fefefe;
  }
  p {
    font-size: 25px;
    padding: 40px;
    font-family: $alegreya-sans;
  }
}

.contact {
  height: 12rem;
  .icons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    p {
      font-size: 20px;
    }
  }

  .icon {
    cursor: pointer;
    margin: 2rem 2rem;
    height: 2rem;
    width: 2rem;
    color: $fontThirdColor;
  }
}

@media (max-width: 600px) {
  .contact {
    margin-top: -1rem;
    margin-bottom: 10rem;
  }
  .icons-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      font-size: 20px;
    }
  }
}

.gallery-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    padding: 3rem;
    font-family: $alegreya-sans;
    color: $myColor;
  }
}

.gallery_grid {
  margin-bottom: 50px;
  --gap: 10px;
  --num-cols: 4;
  --row-height: 300px;
  box-sizing: border-box;
  padding: var(--gap);
  display: grid;
  /* 4 columns, equal */
  grid-template-columns: repeat(var(--num-cols), 1fr);
  /* unlimited amount of 300px rows in the grid */
  grid-auto-rows: repeat(var(--row-height));
  gap: var(--gap);
  flex-wrap: wrap;
  transition: 0.2s;
  img {
    image-rendering: crisp-edges;
  }
}

@media (max-width: 600px) {
  .gallery-container {
    h1 {
      text-align: center;
    }
  }
  .gallery_grid {
    --num-cols: 2;
    grid-template-columns: repeat(var(--num-cols), 1fr);
  }
}

.footer-container {
  display: flex;
  color: white;
  font-size: 12px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  background-color: black;
  p {
    margin: 20px;
  }
}

.hawthorn-footer {
  display: flex;
  justify-content: center;
  align-items: center;

  .fbook-logo {
    color: #fefefe;
    height: 20px;
    width: 20px;
  }
}
